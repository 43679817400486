import { template as template_7570f7a4fa964f2889c3b5d4efa95619 } from "@ember/template-compiler";
const WelcomeHeader = template_7570f7a4fa964f2889c3b5d4efa95619(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
