import { template as template_ed6d83056f624768a8fbb8454abd5861 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_ed6d83056f624768a8fbb8454abd5861(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
